<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img class="wp-image-23433 aligncenter" src="@/assets/news/15/1.png" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>爱尔兰移民许可</p>
        <p>现已统一延期到2022年5月31日</p>
        <p>继上次爱尔兰司法部将移民及短期停留签证的居留许可统一延期至2022年1月15日之后，爱尔兰司法部部长 Heather Humphreys宣布，再次延长移民及短期停留签证的居留许可至2022年5月31日。</p>
        <p>该项规定适用于2022年1月15日至2022年5月31日之间到期的所有许可人员。所有在此临时延期范围内的人员需要在2022年5月31日之前登记或更新他们的居留许可，以确保他们有资格在该日期之后能合法留在爱尔兰。</p>
        <p>（资料来源：https://justice.ie/en/JELR/Pages/PR21000306）</p>
        <p><img class="size-full wp-image-23434 aligncenter" src="@/assets/news/15/2.jpg" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>本次居留许可的临时延期，意味着在2020年3月持有有效许可的人，即使他们的爱尔兰居留卡（IRP）已经过期，正在等待新的居留许可，也可以合法地留在爱尔兰，直至2022年5月31日。</p>
        <p>其他特殊的安排将继续适用于国际学生，包括英语语言学生，具体如下：</p>
        <p>可以获得新IRP卡的人可继续使用他们目前的过期卡，他们能够在圣诞节期间和2022年1月15日之前放心地往返爱尔兰。</p>
        <p>计划在2022年1月15日之后出国旅行的成年人需要申请更新他们的居留许可并获得新的IRP卡。否则，他们必须旅行前在爱尔兰获得再入境签证，或在返回前在海外签证办公室获得再入境签证。</p>
        <p>对于已经居住在爱尔兰并与合法居住的父母一起旅行的所有未成年人，目前暂停的再入境签证要求将与新的延期规定保持一致，延长至2022年5月31日。</p>
        <p>目前，位于Burgh Quay的公共注册办公室已开放预约，都柏林地区的客户续签可以网上进行，网址：https://inisonline.jahs.ie</p>
        <p>除都柏林以外的地区，登记和续签工作照旧，所有注册办事处的联系细节信息可在以下网站获得：https://www.garda.ie/en/about-us/our-departments/office-of-corporate-communications/news-media/reopening-of-registration-offices.html</p>
        <p>自新冠疫情蔓延以来，爱尔兰政府一直在积极应对，防御措施不断升级，便民举措层出不穷。</p>
        <p>为了让投资人能更灵活地准备和递交移民申请，解除只能在窗口期递交申请的限制，目前，<a href="/irelandiip">爱尔兰移民</a>局已全面取消窗口期！申请人可以随时递交申请，不再受窗口期限制！</p>
        <p><img class="size-full wp-image-23435 aligncenter" src="@/assets/news/15/3.jpg" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>此前一直观望已久的投资人，可以借此机会，做足准备，搭上这趟顺风车。</p>
        <p><span style="color: #0000ff;"><strong>爱尔兰投资移民优势</strong></span></p>
        <p><strong>优质教育：</strong></p>
        <p>•爱尔兰教育系统排名世界第七*</p>
        <p>•爱尔兰所有大学都排名世界前列</p>
        <p>•欧盟国家、美国和英国的知名企业青睐爱尔兰毕业生</p>
        <p>•子女免费就读公立小学和中学，大学的学费远低于英美</p>
        <p>•爱尔兰和英国互相承认中学学分</p>
        <p>*资料来源：2019年IMD世界竞争力排名</p>
        <p><strong>就业优势：</strong></p>
        <p>•Google, Facebook, IBM, Accenture, 辉瑞等超过1150家跨国企业将欧洲总部设在爱尔兰</p>
        <p>•大量优质、高薪的就业机会，如，Google在爱尔兰雇员人数超7,000人，Apple超6,000人</p>
        <p>•2021年，爱尔兰全职员工的平均年收入为53817欧元</p>
        <p>•约78%的爱尔兰毕业生离校后九个月内就能找到工作</p>
        <p><strong>投资与经商宝地：</strong></p>
        <p>•2014-2018连续五年经济增长率保持欧盟超高水平</p>
        <p>•2011-2017年度IBM全球区域趋势报告称爱尔兰为外资直接投资量超大国</p>
        <p>•2017年度IMD世界竞争力年鉴评价爱尔兰为优秀投资回报国</p>
        <p>•2013年福布斯评价爱尔兰为世界优质经商国</p>
        <p>•国家综合知识、国家繁荣度和平等水平排名世界前列</p>
        <p>•企业所得税税率低至12.5%</p>
        <p><strong>移民政策宽松：</strong></p>
        <p>•先获批再投资</p>
        <p>•无语言要求</p>
        <p>•无管理经验要求</p>
        <p>•无移民监</p>
        <p>•6个月左右即可获批</p>
        <p><strong>护照含金量高：</strong></p>
        <p>爱尔兰护照的价值相当于英国护照+欧盟护照。</p>
        <p>•英国护照：根据爱尔兰与英国签署的共同区域协定CTA，爱尔兰公民前往英国无需申请签证，且在英国无需申请永居，进英国后可自动享有自由定居、工作、学习、享受社会福利甚至选举的权利</p>
        <p>•欧盟护照：爱尔兰是欧盟国家，持爱尔兰护照可以在欧盟成员国读书、工作</p>
        <p>跃迁爱尔兰投资移民项目助您圆梦爱尔兰</p>
        <p><img loading="lazy" class="size-full wp-image-23436 aligncenter" src="@/assets/news/15/4.jpg" alt="图片" width="573" height="442"></p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news15",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:15,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>